import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

const WORDS_PER_SECOND = 3.3;
const MINIMUM_DURATION = 4;
const MAXIMUM_DURATION = 10;

const DEFAULT_ACTION_TEXT = 'GALAXY.SNACKBAR.ACTION_BUTTON.DISMISS';

/* Additional options to customize a snackbar */
export interface SnackBarOptions {
  /* custom text or translation key for the action button */
  action?: string;
  /* custom duration snackbar stays open */
  duration?: number;
  /* action to be performed when user clicks action button */
  actionCallback?: () => void;
  /* parameters uses to interpolate values into translated strings */
  interpolateTranslateParams?: { [key: string]: string };
  /* additional classes to apply to snackbar */
  panelClass?: string[] | string;
}

/* Additional options to customize a notification snackbar */
export type SnackBarNotificationOptions = Pick<
  SnackBarOptions,
  'action' | 'actionCallback' | 'duration' | 'interpolateTranslateParams'
>;

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  constructor(private snackbarService: MatSnackBar, private translateService: TranslateService) {}

  /** @deprecated use {@link SnackbarService#openSuccessSnack} **/
  successSnack(
    messageText: string,
    actionText?: SnackBarNotificationOptions['action'],
    duration?: SnackBarNotificationOptions['duration'],
    callback?: SnackBarNotificationOptions['actionCallback'],
    interpolateTranslateParams?: SnackBarNotificationOptions['interpolateTranslateParams'],
  ): MatSnackBarRef<SimpleSnackBar> {
    return this.openSuccessSnack(messageText, {
      action: actionText,
      actionCallback: callback,
      duration,
      interpolateTranslateParams,
    });
  }

  /** @deprecated use {@link SnackbarService#openErrorSnack} **/
  errorSnack(
    messageText: string,
    actionText?: SnackBarNotificationOptions['action'],
    duration?: SnackBarNotificationOptions['duration'],
    callback?: SnackBarNotificationOptions['actionCallback'],
    interpolateTranslateParams?: SnackBarNotificationOptions['interpolateTranslateParams'],
  ): MatSnackBarRef<SimpleSnackBar> {
    return this.openErrorSnack(messageText, {
      action: actionText,
      actionCallback: callback,
      duration,
      interpolateTranslateParams,
    });
  }

  /** @deprecated use {@link SnackbarService#openWithOptions} **/
  openSnackBar(
    messageText: string,
    actionText?: SnackBarOptions['action'],
    duration?: SnackBarOptions['duration'],
    callback?: SnackBarOptions['actionCallback'],
    interpolateTranslateParams?: SnackBarOptions['interpolateTranslateParams'],
    panelClass?: string[],
  ): MatSnackBarRef<SimpleSnackBar> {
    return this.openWithOptions(messageText, {
      duration,
      action: actionText,
      actionCallback: callback,
      panelClass,
      interpolateTranslateParams,
    });
  }

  /*
  Calculates the duration in milliseconds a snack-message should have, based on the length of the message.
  The docs say snacks go from 4 - 10 seconds:
      - https://material.io/components/snackbars#behavior
  These sites say humans read at around 200 - 500 words per minute:
      - https://secure.execuread.com/facts/
      - https://irisreading.com/what-is-the-average-reading-speed/
  */
  durationForText(message: string): number {
    const words = message.match(/(\w+)/g);

    if (!words) {
      return MINIMUM_DURATION * 1000;
    }
    const numberOfWords = words.length;
    let duration = Math.ceil((numberOfWords / WORDS_PER_SECOND) * 2) / 2;
    duration = Math.max(MINIMUM_DURATION, Math.min(MAXIMUM_DURATION, duration));
    return duration * 1000;
  }

  openSuccessSnack(message: string, options?: SnackBarNotificationOptions): MatSnackBarRef<SimpleSnackBar> {
    return this.openWithOptions(message, { ...options, panelClass: ['success-snackbar'] });
  }

  openErrorSnack(message: string, options?: SnackBarNotificationOptions): MatSnackBarRef<SimpleSnackBar> {
    return this.openWithOptions(message, { ...options, panelClass: ['error-snackbar'] });
  }

  openWithOptions(
    message: string,
    { duration, action, actionCallback, panelClass, interpolateTranslateParams }: SnackBarOptions = {},
  ): MatSnackBarRef<SimpleSnackBar> {
    const classes = typeof panelClass === 'string' ? [panelClass] : panelClass ?? [];
    const translatedMessage = this.translateService.instant(message, interpolateTranslateParams);
    const config = {
      ...new MatSnackBarConfig<SimpleSnackBar>(),
      panelClass: ['regular-snackbar', ...classes],
      duration: duration || this.durationForText(translatedMessage),
    };

    const translatedAction = this.translateService.instant(action || DEFAULT_ACTION_TEXT, interpolateTranslateParams);
    const snackBarRef = this.snackbarService.open(translatedMessage, translatedAction, config);

    if (actionCallback) {
      snackBarRef
        .onAction()
        .pipe(take(1))
        .subscribe(() => actionCallback());
    }

    return snackBarRef;
  }
}
