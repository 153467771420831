{
  "COMMON": {
    "OK": "Ok",
    "PASSWORD": "Password",
    "SUBMIT": "Submit"
  },
  "RESET_PASSWORD": {
    "ENTER_NEW_PASSWORD": "Enter a new password",
    "HEADER": "Reset password",
    "REQUEST_ANOTHER_PASSWORD_RESET": "Request another password reset",
    "SUCCESS": "Your password has been reset."
  },
  "VERIFY_EMAIL": {
    "EXPIRED_TOKEN": "Your token was expired. We have resent the verification email.",
    "UNKNOWN_ERROR": "Unknown error occurred. Please retry",
    "INVALID_TOKEN": "Invalid token",
    "HEADER": "Verify your email address",
    "BUTTON": "Verify email address",
    "BODY": "To confirm that you have access to {{ email }}, please complete the verification using the button below.",
    "SUCCESS": "Your email has been verified. You can close this tab"
  },
  "ACCOUNT_LOGIN": {
    "EMAIL": "Email",
    "PASSWORD": "Password",
    "REMEMBER_ME": "Remember me",
    "FORGOT_PASSWORD": "Forgot password?",
    "SIGN_IN": "Sign in",
    "OR": "or",
    "SIGN_IN_WITH_GOOGLE": "Sign in with Google",
    "SIGN_IN_WITH_MICROSOFT": "Sign in with Microsoft",
    "SIGN_IN_WITH_LINKEDIN": "Sign in with LinkedIn",
    "SUCCESS": "Success",
    "ERRORS": {
      "NO_SERVICE_PROVIDER_ID": "ServiceProviderId not set - can't login.",
      "NO_USER_ID": "UserId not set - can't login.",
      "POST_LOGIN_REDIRECT_FAILURE": "Failed to login automatically. Please try again",
      "INCORRECT_EMAIL_OR_PASSWORD": "Incorrect email/password.",
      "DEFAULT_ERROR": "An error occurred",
      "MUST_RESET_PASSWORD": "You must reset your password before you can log into this account",
      "GOOGLE_SIGN_IN_REQUIRED": "You can no longer use an email/password combination for this account. Please sign in with Google.",
      "ACCOUNT_LOCKED": "Your account is locked due to too many login attempts. Please wait a while before trying to log in again.",
      "ACCOUNT_FROZEN": "Your account is frozen. Please contact support.",
      "VERIFICATION_REQUIRED": "You must verify your email before you can log in. An email has been sent to you.",
      "UNKNOWN_ERROR": "Unknown error"
    }
  },
  "ACCOUNT_LOGOUT": {
    "SIGNING_OUT": "Signing out",
    "LOGOUT_FAILED": "Logout failed."
  },
  "ACCOUNT_CHOOSER": {
    "SELECT_ACCOUNT": "Select an Account",
    "SELECT_ANOTHER_ACCOUNT": "Select another account",
    "SEARCH": "Search",
    "REMOVE_ACCOUNT": "Remove {{email}} from this list.",
    "SIGNED_OUT": "Signed out"
  },
  "ACCOUNT_REQUEST_PASSWORD": {
    "REQUEST_PASSWORD_RESET": "Request password reset",
    "WE_WILL_SEND_YOU_A_LINK": "We will send you a link to reset your password",
    "EMAIL": "Email",
    "SUBMIT": "Submit",
    "CHECK_YOUR_EMAIL": "Check your email for a link to reset your password",
    "OK": "Ok",
    "ERROR_HAS_OCCURED": "An error occurred."
  },
  "FOOTER": {
    "NEW_HERE": "New here?",
    "GET_STARTED": "Get Started with Vendasta",
    "AGREEMENTS": "By continuing, you are indicating that you accept our <a href=\"{{termsOfServiceUrl}}\">Terms of Service</a> and <a href=\"{{privacyPolicyUrl}}\">Privacy Policy</a>."
  }
}
